var render = function () {
  var _vm$getLoggedData$pri, _vm$getLoggedData$pri2, _vm$getLoggedData$pri3, _vm$getLoggedData$pri4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header__wrapper"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('header', {
    staticClass: "header__container"
  }, [_c('h2', {
    staticClass: "blind-a11y"
  }, [_vm._v("큐픽 회원")]), _c('a', {
    staticClass: "header__logo cursor ga_a_01",
    staticStyle: {
      "z-index": "2"
    },
    on: {
      "click": function click($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.$router.push('/admin/dashboard');
      }
    }
  }, [_vm._v(" Qpick ")]), _vm.getLoggedData.grade > 0 ? _c('div', {
    staticClass: "account"
  }, [_c('button', {
    staticClass: "account--active",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleAccountLayer.apply(null, arguments);
      }
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$getLoggedData$pri = _vm.getLoggedData.privacy) === null || _vm$getLoggedData$pri === void 0 ? void 0 : _vm$getLoggedData$pri.email))])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.accountLayer,
      expression: "accountLayer"
    }, {
      name: "click-outside",
      rawName: "v-click-outside:toggleAccountLayer",
      value: false,
      expression: "false",
      arg: "toggleAccountLayer"
    }],
    staticClass: "account__layer"
  }, [_c('button', {
    staticClass: "account--live",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.toggleAccountLayer
    }
  }, [_c('span', {
    staticClass: "account__id"
  }, [_vm._v(" " + _vm._s((_vm$getLoggedData$pri2 = _vm.getLoggedData.privacy) === null || _vm$getLoggedData$pri2 === void 0 ? void 0 : _vm$getLoggedData$pri2.email) + " ")]), _c('span', {
    staticClass: "account__name"
  }, [_vm._v(" " + _vm._s((_vm$getLoggedData$pri3 = _vm.getLoggedData.privacy) === null || _vm$getLoggedData$pri3 === void 0 ? void 0 : _vm$getLoggedData$pri3.name) + " ")])])])]) : _vm._e(), _c('h2', {
    staticClass: "blind-a11y"
  }, [_vm._v("큐픽 메뉴")]), _c('ul', {
    staticClass: "topNav"
  }, [_vm.getLoggedData.grade !== 2 && _vm.getUserProgressStepShow ? _c('li', [_c('button', {
    staticClass: "topNav__guide",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleMenuLayer(true, 'sideLayer', 'guide');
      }
    }
  }, [_vm._v(" 큐픽 제작 가이드 ")])]) : _vm._e(), _vm.getLoggedData.grade !== 2 ? _c('li', [_c('button', {
    staticClass: "topNav__notice ga_a_02",
    class: {
      'topNav__notice--active': _vm.noCheckAlarm.list.length
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.noCheckAlarm.list.length ? _vm.toggleMenuLayer(true, 'sideLayer', 'alarm') : _vm.$router.push({
          name: 'Alarm'
        });
      }
    }
  }, [_vm._v(" 알림 ")])]) : _vm._e(), _vm.getLoggedData.grade === 2 ? _c('li', [_c('button', {
    staticClass: "topNav__logout",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/accounts/logout');
      }
    }
  }, [_vm._v(" 로그아웃 ")])]) : _c('li', [_c('button', {
    staticClass: "topNav__user",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleUserLayer.apply(null, arguments);
      }
    }
  }, [_vm._v(" 회원 ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userLayer,
      expression: "userLayer"
    }, {
      name: "click-outside",
      rawName: "v-click-outside:toggleUserLayer",
      value: false,
      expression: "false",
      arg: "toggleUserLayer"
    }],
    staticClass: "topNav__layer topNav__layer--user"
  }, [_c('button', {
    staticClass: "topNav__layer--close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.toggleUserLayer
    }
  }, [_vm._v(" 레이어 닫기 ")]), _c('ul', {
    staticClass: "utils__user"
  }, [_vm.getLoggedData.grade === 1 ? _c('li', [_c('a', {
    staticClass: "utils__user--lock",
    attrs: {
      "href": "/accounts/personal",
      "target": "_blank"
    }
  }, [_vm._v(" 계정정보 ")])]) : _vm._e(), _vm.getLoggedData.grade === 0 ? _c('li', [_c('a', {
    staticClass: "utils__user--switch",
    attrs: {
      "href": "/accounts/gradeup"
    }
  }, [_vm._v(" 회원전환 ")])]) : _vm._e(), _vm._m(0)])])]), _c('li', [_c('button', {
    staticClass: "topNav__info",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleInfoLayer.apply(null, arguments);
      }
    }
  }, [_vm._v(" 정보 ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.infoLayer,
      expression: "infoLayer"
    }, {
      name: "click-outside",
      rawName: "v-click-outside:toggleInfoLayer",
      value: false,
      expression: "false",
      arg: "toggleInfoLayer"
    }],
    staticClass: "topNav__layer"
  }, [_c('button', {
    staticClass: "topNav__layer--close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.toggleInfoLayer
    }
  }, [_vm._v(" 레이어 닫기 ")]), _c('ul', {
    staticClass: "utils__info"
  }, [_c('li', [_c('a', {
    staticClass: "utils__info--start ga_a_08",
    attrs: {
      "href": _vm.linkStart,
      "target": "_blank"
    }
  }, [_vm._v(" 시작하기 ")])]), _c('li', [_c('a', {
    staticClass: "utils__info--tip ga_a_09",
    attrs: {
      "href": _vm.linkTip,
      "target": "_blank"
    }
  }, [_vm._v(" 사용팁 ")])]), _c('li', [_c('a', {
    staticClass: "utils__info--notice ga_a_10",
    attrs: {
      "href": _vm.linkNotice,
      "target": "_blank"
    }
  }, [_vm._v(" 공지사항 ")])]), _c('li', [_c('a', {
    staticClass: "utils__info--faq ga_a_11",
    attrs: {
      "href": _vm.linkFaq,
      "target": "_blank"
    }
  }, [_vm._v(" FAQ ")])]), _c('li', [_c('a', {
    staticClass: "utils__info--help ga_a_12",
    attrs: {
      "href": _vm.linkInquiry,
      "target": "_blank"
    }
  }, [_vm._v(" 1:1 문의 ")])])])])]), _c('li', [_c('button', {
    staticClass: "topNav__menu",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.getDeviceType === 'mobile' ? _vm.toggleMenuLayer(true, 'sideLayer', 'menu') : _vm.toggleMenuLayer(true);
      }
    }
  }, [_vm._v(" 메뉴 ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.menuLayer,
      expression: "menuLayer"
    }, {
      name: "click-outside",
      rawName: "v-click-outside:toggleMenuLayer",
      value: false,
      expression: "false",
      arg: "toggleMenuLayer"
    }],
    staticClass: "topNav__layer"
  }, [_c('button', {
    staticClass: "topNav__layer--close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.toggleMenuLayer
    }
  }, [_vm._v(" 레이어 닫기 ")]), _c('ul', {
    staticClass: "utils__menu"
  }, [_vm.getLoggedData.grade < 2 ? _c('li', [_c('a', {
    staticClass: "utils__menu--solution ga_a_04",
    attrs: {
      "href": "/admin/solution"
    }
  }, [_vm._v(" 솔루션 연동 관리 ")])]) : _vm._e(), _vm._m(1), _vm._m(2), _vm.getLoggedData.grade < 2 ? _c('li', [_c('a', {
    staticClass: "utils__menu--notice ga_a_07",
    attrs: {
      "href": "/admin/alarm"
    }
  }, [_vm._v("알림센터")])]) : _vm._e()])])])])])]), _c('aside', {
    staticClass: "aside"
  }, [_c('transition', {
    attrs: {
      "name": "opacity",
      "mode": "out-in"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.sideLayer,
      expression: "sideLayer"
    }],
    staticClass: "dimmed",
    on: {
      "click": function click($event) {
        return _vm.toggleMenuLayer(false, 'sideLayer');
      }
    }
  })]), _c('transition', {
    attrs: {
      "name": "slide",
      "mode": "out-in"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.sideLayer,
      expression: "sideLayer"
    }],
    staticClass: "aside__inner",
    class: {
      'aside__inner--pop': _vm.sideContent === 'alarm' || _vm.sideContent === 'guide'
    }
  }, [_vm.sideContent === 'menu' ? _c('div', {
    staticClass: "aside-menu"
  }, [_c('h2', {
    staticClass: "blind-a11y"
  }, [_vm._v("큐픽 메뉴")]), _c('button', {
    staticClass: "aside--close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleMenuLayer(false, 'sideLayer');
      }
    }
  }, [_vm._v(" 레이어 닫기 ")]), _c('section', {
    staticClass: "logNav"
  }, [_c('span', {
    staticClass: "logNav__id",
    class: {
      'logNav__id--associate': _vm.getLoggedData.grade === 0
    }
  }, [_vm._v(" " + _vm._s(_vm.getLoggedData.grade === 1 ? (_vm$getLoggedData$pri4 = _vm.getLoggedData.privacy) === null || _vm$getLoggedData$pri4 === void 0 ? void 0 : _vm$getLoggedData$pri4.email : _vm.getLoggedData.grade === 0 ? "\uC900\uD68C\uC6D0(".concat(_vm.getLoggedData.id, ")") : "") + " ")]), _c('div', {
    staticClass: "logNav__link"
  }, [_vm.getLoggedData.grade === 1 ? _c('a', {
    attrs: {
      "href": "/accounts/personal"
    }
  }, [_vm._v("계정정보")]) : _vm._e(), _vm.getLoggedData.grade === 0 ? _c('a', {
    attrs: {
      "href": "/accounts/gradeup"
    }
  }, [_vm._v(" 회원전환 ")]) : _vm._e(), _c('a', {
    attrs: {
      "href": "/accounts/logout"
    }
  }, [_vm._v("로그아웃")])])]), _c('div', {
    staticClass: "sideNav"
  }, [_c('section', {
    staticClass: "sideNav__site"
  }, [_c('h3', [_vm._v("사이트 관리")]), _c('button', {
    staticClass: "sideNav__toggle",
    class: {
      'sideNav__toggle--show': !_vm.sideMenu.site
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleSideMenu('site');
      }
    }
  }, [_vm._v(" 열기 ")]), !Object.keys(_vm.getLoggedData.sites).length ? _c('button', {
    staticClass: "sideNav__site--none",
    class: {
      'sideNav--hide': !_vm.sideMenu.site
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.siteAdd
    }
  }, [_vm._v(" 사이트 추가 ")]) : _c('ul', {
    ref: "site",
    staticClass: "utils__site",
    class: {
      'sideNav--hide': !_vm.sideMenu.site
    }
  }, _vm._l(_vm.getLoggedData.sites, function (site, i) {
    var _site$attachFiles, _site$attachFiles2;

    return _c('li', {
      key: i
    }, [_c('a', {
      attrs: {
        "href": "/admin/theme/".concat(site.id)
      }
    }, [_c('dl', [_c('dt', [(_site$attachFiles = site.attachFiles) !== null && _site$attachFiles !== void 0 && _site$attachFiles.url ? _c('img', {
      attrs: {
        "src": (_site$attachFiles2 = site.attachFiles) === null || _site$attachFiles2 === void 0 ? void 0 : _site$attachFiles2.url
      }
    }) : _c('span', [_vm._v(_vm._s(site.name.slice(0, 1)))])]), _c('dd', [_vm._v(" " + _vm._s(site.name) + " ")])])])]);
  }), 0)]), _c('section', {
    staticClass: "sideNav__info"
  }, [_c('h3', [_vm._v("헬프센터")]), _c('button', {
    staticClass: "sideNav__toggle",
    class: {
      'sideNav__toggle--show': !_vm.sideMenu.info
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleSideMenu('info');
      }
    }
  }, [_vm._v(" 열기 ")]), _c('ul', {
    ref: "info",
    staticClass: "utils__info",
    class: {
      'sideNav--hide': !_vm.sideMenu.info
    }
  }, [_c('li', [_c('a', {
    staticClass: "utils__info--start ga_a_08",
    attrs: {
      "href": _vm.linkStart,
      "target": "_blank"
    }
  }, [_vm._v(" 시작하기 ")])]), _c('li', [_c('a', {
    staticClass: "utils__info--tip ga_a_09",
    attrs: {
      "href": _vm.linkTip,
      "target": "_blank"
    }
  }, [_vm._v(" 사용팁 ")])]), _c('li', [_c('a', {
    staticClass: "utils__info--notice ga_a_10",
    attrs: {
      "href": _vm.linkNotice,
      "target": "_blank"
    }
  }, [_vm._v(" 공지사항 ")])]), _c('li', [_c('a', {
    staticClass: "utils__info--faq ga_a_11",
    attrs: {
      "href": _vm.linkFaq,
      "target": "_blank"
    }
  }, [_vm._v(" FAQ ")])]), _c('li', [_c('a', {
    staticClass: "utils__info--help ga_a_12",
    attrs: {
      "href": _vm.linkInquiry,
      "target": "_blank"
    }
  }, [_vm._v(" 1:1 문의 ")])])])]), _c('section', {
    staticClass: "sideNav__menu"
  }, [_c('h3', [_vm._v("관리설정")]), _c('button', {
    staticClass: "sideNav__toggle",
    class: {
      'sideNav__toggle--show': !_vm.sideMenu.menu
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleSideMenu('menu');
      }
    }
  }, [_vm._v(" 열기 ")]), _c('ul', {
    ref: "menu",
    staticClass: "utils__menu",
    class: {
      'sideNav--hide': !_vm.sideMenu.menu
    }
  }, [_vm.getLoggedData.grade < 2 ? _c('li', [_c('a', {
    staticClass: "utils__menu--solution ga_a_04",
    attrs: {
      "href": "/admin/solution"
    }
  }, [_vm._v(" 솔루션 연동 관리 ")])]) : _vm._e(), _c('li', [_c('a', {
    staticClass: "utils__menu--image ga_a_05",
    attrs: {
      "href": "/admin/image"
    }
  }, [_vm._v(" 이미지 관리 ")])]), _c('li', [_c('a', {
    staticClass: "utils__menu--export ga_a_06",
    attrs: {
      "href": "/admin/export-history"
    }
  }, [_vm._v("내보내기 조회")])]), _vm.getLoggedData.grade < 2 ? _c('li', [_c('a', {
    staticClass: "utils__menu--notice ga_a_07",
    attrs: {
      "href": "/admin/alarm"
    }
  }, [_vm._v(" 알림센터 ")])]) : _vm._e()])])])]) : _vm._e(), _vm.sideContent === 'guide' ? _c('div', {
    staticClass: "aside-pop"
  }, [_c('div', {
    staticClass: "pop-top-wrap"
  }, [_c('div', {
    staticClass: "pop-top"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" 큐픽 제작 가이드 "), _c('tooltip', {
    attrs: {
      "text": "\uAC01 \uB2E8\uACC4\uBCC4 \uC548\uB0B4 \uB0B4\uC6A9\uC744 \uCC38\uACE0\uD558\uC154\uC11C \uD050\uD53D \uD14C\uB9C8 \uC81C\uC791\uC744 \uC9C4\uD589\uD574\uC8FC\uC138\uC694.<br />\uC644\uB8CC\uCC98\uB9AC \uB41C \uB0B4\uC5ED\uC744 \u2018\uCDE8\uC18C/\uC0AD\uC81C/\uD574\uC81C\u2019 \uCC98\uB9AC \uB420 \uACBD\uC6B0 \uB2E4\uC2DC \uBBF8\uC644\uB8CC \uC0C1\uD0DC\uB85C \uC804\uD658\uB429\uB2C8\uB2E4."
    }
  })], 1)])]), _c('div', {
    staticClass: "guide-wrap"
  }, [_c('progress-guide')], 1)]) : _vm._e(), _vm.sideContent === 'alarm' ? _c('div', {
    staticClass: "aside-pop"
  }, [_c('div', {
    staticClass: "pop-top-wrap"
  }, [_c('div', {
    staticClass: "pop-top"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" 미확인 알림 "), _c('tooltip', {
    attrs: {
      "text": "\uC54C\uB9BC \uC13C\uD130\uC5D0\uC11C\uB294 \uD050\uD53D \uC11C\uBE44\uC2A4 \uBC0F \uB2E4\uC591\uD55C \uD61C\uD0DD \uC815\uBCF4\uB97C \uC548\uB0B4\uD574 \uB4DC\uB9AC\uACE0\uC788\uC2B5\uB2C8\uB2E4.<br />[\uBAA8\uB450 \uD655\uC778] \uBC84\uD2BC\uC744 \uD074\uB9AD\uD558\uC2DC\uBA74, \uBBF8\uD655\uC778 \uC54C\uB9BC\uC774 \uBAA8\uB450 \uD655\uC778 \uC54C\uB9BC \uC0C1\uD0DC\uB85C \uC77C\uAD04 \uC5C5\uB370\uC774\uD2B8\uB418\uACE0, [\uC54C\uB9BC \uC804\uCCB4 \uBCF4\uAE30] \uBC84\uD2BC\uC744 \uD074\uB9AD\uD558\uC2DC\uBA74 \uC54C\uB9BC \uB0B4\uC5ED \uC804\uCCB4\uB97C \uD655\uC778\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4"
    }
  })], 1), _c('div', {
    staticClass: "confirm-button ga_a_03",
    class: {
      'confirm-button-disable': !_vm.noCheckAlarm.list.length
    },
    on: {
      "click": function click($event) {
        _vm.noCheckAlarm.list.length ? _vm.alarmCheckAllUpdate() : '';
      }
    }
  }, [_vm.noCheckAlarm.list.length ? _c('img', {
    attrs: {
      "src": require("@/assets/images/admin/icon_check_alarm.svg")
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/images/admin/icon_check_alarm_grey.svg")
    }
  }), _vm._v(" 모두 확인 ")])])]), _c('div', {
    staticClass: "alarm-wrap"
  }, [_c('div', {
    staticClass: "alarm-list-wrap"
  }, [_c('ul', {
    staticClass: "alarm-list"
  }, _vm._l(_vm.noCheckAlarm.list, function (list, i) {
    return _c('li', {
      key: i
    }, [_c('div', {
      staticClass: "text"
    }, [_vm._v(_vm._s(list.contents))]), _c('div', {
      staticClass: "detail-wrap"
    }, [_c('div', {
      staticClass: "date"
    }, [_vm._v(_vm._s(_vm._f("date")(list.sentAt)))]), _c('div', {
      staticClass: "detail-button"
    }, [_c('a', {
      attrs: {
        "href": _vm._f("notification_code")(list.code),
        "target": "_blank"
      },
      on: {
        "click": function click($event) {
          return _vm.alarmCheckUpdate(list.id);
        }
      }
    }, [_vm._v(" 상세보기 ")])])])]);
  }), 0), _vm.noCheckAlarm.page !== _vm.noCheckAlarm.totalPage ? _c('div', {
    staticClass: "more-button",
    on: {
      "click": function click($event) {
        return _vm.alarmList();
      }
    }
  }, [_vm._v(" 더보기 + ")]) : _vm._e(), !_vm.noCheckAlarm.list.length ? _c('div', {
    staticClass: "alarm-list-empty"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/admin/icon_empty_alarm.svg")
    }
  }), _c('p', [_vm._v("미확인 알림이 없습니다.")])]) : _vm._e()]), _c('a', {
    attrs: {
      "href": "/admin/alarm"
    }
  }, [_c('div', {
    staticClass: "all-button"
  }, [_vm._v("알림 전체 보기")])])])]) : _vm._e()])])], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    staticClass: "utils__user--logout",
    attrs: {
      "href": "/accounts/logout"
    }
  }, [_vm._v(" 로그아웃 ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    staticClass: "utils__menu--image ga_a_05",
    attrs: {
      "href": "/admin/image"
    }
  }, [_vm._v(" 이미지 관리 ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    staticClass: "utils__menu--export ga_a_06",
    attrs: {
      "href": "/admin/export-history"
    }
  }, [_vm._v("내보내기 조회")])]);
}]

export { render, staticRenderFns }