<template>
  <div class="header__wrapper">
    <div class="header">
      <header class="header__container">
        <h2 class="blind-a11y">큐픽 회원</h2>
        <a
          @click.self="$router.push('/admin/dashboard')"
          class="header__logo cursor ga_a_01"
          style="z-index: 2"
        >
          Qpick
        </a>
        <div class="account" v-if="getLoggedData.grade > 0">
          <button
            type="button"
            class="account--active"
            @click.stop="toggleAccountLayer"
          >
            <span>{{ getLoggedData.privacy?.email }}</span>
          </button>
          <div
            class="account__layer"
            v-show="accountLayer"
            v-click-outside:toggleAccountLayer="false"
          >
            <button
              type="button"
              class="account--live"
              @click="toggleAccountLayer"
            >
              <span class="account__id">
                {{ getLoggedData.privacy?.email }}
              </span>
              <span class="account__name">
                {{ getLoggedData.privacy?.name }}
              </span>
            </button>
            <!-- 초대회원 추후 오픈 -->
            <!--ul class="account__list">
              <li>
                <button type="button" @click="toggleAccountLayer">
                  <span class="account__id">lucky777@naver.com</span>
                  <span class="account__name">주중 매니저 김희진</span>
                </button>
              </li>
              <li>
                <button type="button" @click="toggleAccountLayer">
                  <span class="account__id">nanak1988@gmail.com</span>
                  <span class="account__name">총괄 매니저 윤승재</span>
                </button>
              </li>
            </ul-->
            <!-- //초대회원 추후 오픈 -->
          </div>
        </div>
        <h2 class="blind-a11y">큐픽 메뉴</h2>
        <ul class="topNav">
          <li v-if="getLoggedData.grade !== 2 && getUserProgressStepShow">
            <button
              type="button"
              class="topNav__guide"
              @click="toggleMenuLayer(true, 'sideLayer', 'guide')"
            >
              큐픽 제작 가이드
            </button>
          </li>
          <li v-if="getLoggedData.grade !== 2">
            <button
              type="button"
              class="topNav__notice ga_a_02"
              :class="{'topNav__notice--active': noCheckAlarm.list.length}"
              @click="
                noCheckAlarm.list.length
                  ? toggleMenuLayer(true, 'sideLayer', 'alarm')
                  : $router.push({name: 'Alarm'})
              "
            >
              알림
            </button>
          </li>
          <li v-if="getLoggedData.grade === 2">
            <button
              type="button"
              class="topNav__logout"
              @click="$router.push('/accounts/logout')"
            >
              로그아웃
            </button>
          </li>
          <li v-else>
            <button
              type="button"
              class="topNav__user"
              @click.stop="toggleUserLayer"
            >
              회원
            </button>
            <div
              class="topNav__layer topNav__layer--user"
              v-show="userLayer"
              v-click-outside:toggleUserLayer="false"
            >
              <button
                type="button"
                class="topNav__layer--close"
                @click="toggleUserLayer"
              >
                레이어 닫기
              </button>
              <ul class="utils__user">
                <li v-if="getLoggedData.grade === 1">
                  <a
                    href="/accounts/personal"
                    class="utils__user--lock"
                    target="_blank"
                  >
                    계정정보
                  </a>
                </li>
                <li v-if="getLoggedData.grade === 0">
                  <a href="/accounts/gradeup" class="utils__user--switch">
                    회원전환
                  </a>
                </li>
                <li>
                  <a href="/accounts/logout" class="utils__user--logout">
                    로그아웃
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <button
              type="button"
              class="topNav__info"
              @click.stop="toggleInfoLayer"
            >
              정보
            </button>
            <div
              class="topNav__layer"
              v-show="infoLayer"
              v-click-outside:toggleInfoLayer="false"
            >
              <button
                type="button"
                class="topNav__layer--close"
                @click="toggleInfoLayer"
              >
                레이어 닫기
              </button>
              <ul class="utils__info">
                <li>
                  <a
                    class="utils__info--start ga_a_08"
                    :href="linkStart"
                    target="_blank"
                  >
                    시작하기
                  </a>
                </li>
                <li>
                  <a
                    class="utils__info--tip ga_a_09"
                    :href="linkTip"
                    target="_blank"
                  >
                    사용팁
                  </a>
                </li>
                <!-- 추후 오픈 -->
                <!--li>
                  <a href="#" class="utils__info--theme">테마소식</a>
                </li-->
                <!--li>
                  <a href="#" class="utils__info--component">컴포넌트소식</a>
                </li-->
                <!-- //추후 오픈 -->
                <li>
                  <a
                    class="utils__info--notice ga_a_10"
                    :href="linkNotice"
                    target="_blank"
                  >
                    공지사항
                  </a>
                </li>
                <li>
                  <a
                    class="utils__info--faq ga_a_11"
                    :href="linkFaq"
                    target="_blank"
                  >
                    FAQ
                  </a>
                </li>
                <li>
                  <a
                    class="utils__info--help ga_a_12"
                    :href="linkInquiry"
                    target="_blank"
                  >
                    1:1 문의
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <button
              type="button"
              class="topNav__menu"
              @click.stop="
                getDeviceType === 'mobile'
                  ? toggleMenuLayer(true, 'sideLayer', 'menu')
                  : toggleMenuLayer(true)
              "
            >
              메뉴
            </button>
            <div
              class="topNav__layer"
              v-show="menuLayer"
              v-click-outside:toggleMenuLayer="false"
            >
              <button
                type="button"
                class="topNav__layer--close"
                @click="toggleMenuLayer"
              >
                레이어 닫기
              </button>
              <ul class="utils__menu">
                <!-- 추후 오픈 -->
                <!--li>
                  <a href="#" class="utils__menu--invite">사용자 초대</a>
                </li-->
                <!-- //추후 오픈 -->
                <li v-if="getLoggedData.grade < 2">
                  <a
                    href="/admin/solution"
                    class="utils__menu--solution ga_a_04"
                  >
                    솔루션 연동 관리
                  </a>
                </li>
                <li>
                  <a href="/admin/image" class="utils__menu--image ga_a_05">
                    이미지 관리
                  </a>
                </li>
                <li>
                  <a
                    href="/admin/export-history"
                    class="utils__menu--export ga_a_06"
                    >내보내기 조회</a
                  >
                </li>
                <li v-if="getLoggedData.grade < 2">
                  <a href="/admin/alarm" class="utils__menu--notice ga_a_07"
                    >알림센터</a
                  >
                </li>
                <!-- 추후 오픈 -->
                <!--li>
                  <a href="#" class="utils__menu--charset">언어변환</a>
                </li-->
                <!-- //추후 오픈 -->
              </ul>
            </div>
          </li>
        </ul>
      </header>
    </div>
    <aside class="aside">
      <transition name="opacity" mode="out-in">
        <div
          class="dimmed"
          @click="toggleMenuLayer(false, 'sideLayer')"
          v-show="sideLayer"
        ></div>
      </transition>
      <transition name="slide" mode="out-in">
        <div
          class="aside__inner"
          v-show="sideLayer"
          :class="{
            'aside__inner--pop':
              sideContent === 'alarm' || sideContent === 'guide'
          }"
        >
          <div v-if="sideContent === 'menu'" class="aside-menu">
            <h2 class="blind-a11y">큐픽 메뉴</h2>
            <button
              type="button"
              class="aside--close"
              @click="toggleMenuLayer(false, 'sideLayer')"
            >
              레이어 닫기
            </button>
            <section class="logNav">
              <span
                class="logNav__id"
                :class="{'logNav__id--associate': getLoggedData.grade === 0}"
              >
                {{
                  getLoggedData.grade === 1
                    ? getLoggedData.privacy?.email
                    : getLoggedData.grade === 0
                    ? `준회원(${getLoggedData.id})`
                    : ""
                }}
              </span>
              <div class="logNav__link">
                <a href="/accounts/personal" v-if="getLoggedData.grade === 1"
                  >계정정보</a
                >
                <a href="/accounts/gradeup" v-if="getLoggedData.grade === 0">
                  회원전환
                </a>
                <a href="/accounts/logout">로그아웃</a>
              </div>
            </section>
            <div class="sideNav">
              <section class="sideNav__site">
                <h3>사이트 관리</h3>
                <button
                  type="button"
                  class="sideNav__toggle"
                  :class="{
                    'sideNav__toggle--show': !sideMenu.site
                  }"
                  @click="toggleSideMenu('site')"
                >
                  열기
                </button>
                <button
                  type="button"
                  class="sideNav__site--none"
                  :class="{'sideNav--hide': !sideMenu.site}"
                  v-if="!Object.keys(getLoggedData.sites).length"
                  @click="siteAdd"
                >
                  사이트 추가
                </button>
                <ul
                  ref="site"
                  class="utils__site"
                  :class="{'sideNav--hide': !sideMenu.site}"
                  v-else
                >
                  <li v-for="(site, i) in getLoggedData.sites" :key="i">
                    <a :href="`/admin/theme/${site.id}`">
                      <dl>
                        <dt>
                          <img
                            :src="site.attachFiles?.url"
                            v-if="site.attachFiles?.url"
                          />
                          <span v-else>{{ site.name.slice(0, 1) }}</span>
                        </dt>
                        <dd>
                          {{ site.name }}
                        </dd>
                      </dl>
                    </a>
                  </li>
                </ul>
              </section>
              <section class="sideNav__info">
                <h3>헬프센터</h3>
                <button
                  type="button"
                  class="sideNav__toggle"
                  :class="{
                    'sideNav__toggle--show': !sideMenu.info
                  }"
                  @click="toggleSideMenu('info')"
                >
                  열기
                </button>
                <ul
                  ref="info"
                  class="utils__info"
                  :class="{'sideNav--hide': !sideMenu.info}"
                >
                  <li>
                    <a
                      class="utils__info--start ga_a_08"
                      :href="linkStart"
                      target="_blank"
                    >
                      시작하기
                    </a>
                  </li>
                  <li>
                    <a
                      class="utils__info--tip ga_a_09"
                      :href="linkTip"
                      target="_blank"
                    >
                      사용팁
                    </a>
                  </li>
                  <li>
                    <a
                      class="utils__info--notice ga_a_10"
                      :href="linkNotice"
                      target="_blank"
                    >
                      공지사항
                    </a>
                  </li>
                  <li>
                    <a
                      class="utils__info--faq ga_a_11"
                      :href="linkFaq"
                      target="_blank"
                    >
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a
                      class="utils__info--help ga_a_12"
                      :href="linkInquiry"
                      target="_blank"
                    >
                      1:1 문의
                    </a>
                  </li>
                </ul>
              </section>
              <section class="sideNav__menu">
                <h3>관리설정</h3>
                <button
                  type="button"
                  class="sideNav__toggle"
                  :class="{
                    'sideNav__toggle--show': !sideMenu.menu
                  }"
                  @click="toggleSideMenu('menu')"
                >
                  열기
                </button>
                <ul
                  ref="menu"
                  class="utils__menu"
                  :class="{'sideNav--hide': !sideMenu.menu}"
                >
                  <li v-if="getLoggedData.grade < 2">
                    <a
                      href="/admin/solution"
                      class="utils__menu--solution ga_a_04"
                    >
                      솔루션 연동 관리
                    </a>
                  </li>
                  <li>
                    <a href="/admin/image" class="utils__menu--image ga_a_05">
                      이미지 관리
                    </a>
                  </li>
                  <li>
                    <a
                      href="/admin/export-history"
                      class="utils__menu--export ga_a_06"
                      >내보내기 조회</a
                    >
                  </li>
                  <li v-if="getLoggedData.grade < 2">
                    <a href="/admin/alarm" class="utils__menu--notice ga_a_07">
                      알림센터
                    </a>
                  </li>
                </ul>
              </section>
            </div>
          </div>
          <!-- 큐픽 제작 가이드 -->
          <div v-if="sideContent === 'guide'" class="aside-pop">
            <div class="pop-top-wrap">
              <div class="pop-top">
                <div class="title">
                  큐픽 제작 가이드
                  <tooltip
                    :text="`각 단계별 안내 내용을 참고하셔서 큐픽 테마 제작을 진행해주세요.<br />완료처리 된 내역을 ‘취소/삭제/해제’ 처리 될 경우 다시 미완료 상태로 전환됩니다.`"
                  />
                </div>
              </div>
            </div>
            <div class="guide-wrap">
              <progress-guide></progress-guide>
            </div>
          </div>
          <!-- 미확인 알림 -->
          <div v-if="sideContent === 'alarm'" class="aside-pop">
            <div class="pop-top-wrap">
              <div class="pop-top">
                <div class="title">
                  미확인 알림
                  <tooltip
                    :text="`알림 센터에서는 큐픽 서비스 및 다양한 혜택 정보를 안내해 드리고있습니다.<br />[모두 확인] 버튼을 클릭하시면, 미확인 알림이 모두 확인 알림 상태로 일괄 업데이트되고, [알림 전체 보기] 버튼을 클릭하시면 알림 내역 전체를 확인할 수 있습니다`"
                  />
                </div>
                <div
                  class="confirm-button ga_a_03"
                  :class="{'confirm-button-disable': !noCheckAlarm.list.length}"
                  @click="noCheckAlarm.list.length ? alarmCheckAllUpdate() : ''"
                >
                  <img
                    v-if="noCheckAlarm.list.length"
                    src="@/assets/images/admin/icon_check_alarm.svg"
                  />
                  <img
                    v-else
                    src="@/assets/images/admin/icon_check_alarm_grey.svg"
                  />
                  모두 확인
                </div>
              </div>
            </div>
            <div class="alarm-wrap">
              <div class="alarm-list-wrap">
                <ul class="alarm-list">
                  <li v-for="(list, i) in noCheckAlarm.list" :key="i">
                    <div class="text">{{ list.contents }}</div>
                    <div class="detail-wrap">
                      <div class="date">{{ list.sentAt | date }}</div>
                      <div class="detail-button">
                        <a
                          :href="list.code | notification_code"
                          target="_blank"
                          @click="alarmCheckUpdate(list.id)"
                        >
                          상세보기
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
                <div
                  class="more-button"
                  v-if="noCheckAlarm.page !== noCheckAlarm.totalPage"
                  @click="alarmList()"
                >
                  더보기 +
                </div>
                <div v-if="!noCheckAlarm.list.length" class="alarm-list-empty">
                  <img src="@/assets/images/admin/icon_empty_alarm.svg" />
                  <p>미확인 알림이 없습니다.</p>
                </div>
              </div>
              <a href="/admin/alarm">
                <div class="all-button">알림 전체 보기</div>
              </a>
            </div>
          </div>
        </div>
      </transition>
    </aside>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import ProgressGuide from "@/components/admin/ProgressGuide.vue";

const ModuleAuth = createNamespacedHelpers("ModuleAuth");
const ModuleAdmin = createNamespacedHelpers("ModuleAdmin");
const ModuleUser = createNamespacedHelpers("ModuleUser");

const AppHelp = process.env.VUE_APP_EXTERNAL_qpick_help_URL;

export default {
  components: {ProgressGuide},
  data() {
    return {
      linkStart: `${AppHelp}/gallery?id=1`,
      linkTip: `${AppHelp}/board?id=2`,
      linkNotice: `${AppHelp}/board?id=3`,
      linkFaq: `${AppHelp}/board?id=4`,
      linkInquiry: `${AppHelp}/inquiry`,
      sideMenu: {
        site: true,
        info: false,
        menu: false
      },
      accountLayer: false,
      userLayer: false,
      infoLayer: false,
      menuLayer: false,
      sideLayer: false,
      sideContent: "menu",
      noCheckAlarm: {
        list: [],
        page: 1,
        totalPage: 1
      }
    };
  },
  computed: {
    ...ModuleAuth.mapGetters(["getLoggedData"]),
    ...ModuleAdmin.mapGetters(["getDeviceType"]),
    ...ModuleUser.mapGetters(["getUserProgressStepShow"]),
    userId() {
      return this.getLoggedData.id;
    }
  },
  watch: {
    sideLayer(v) {
      if (v) {
        document.querySelector("#app").style.overflow = "hidden";
        document.querySelector("body").style.overflow = "hidden";
      } else {
        document.querySelector("#app").style.overflow = "";
        document.querySelector("body").style.overflow = "";
      }
    },
    $route(to, from) {
      if (to.path != from.path) {
        this.userProgreeStep();
      }
    }
  },
  methods: {
    // sideMenu 토글
    toggleSideMenu(el) {
      this.sideMenu[el] = !this.sideMenu[el];
    },
    // 계정 레이어 토글
    toggleAccountLayer(value) {
      this.accountLayer = !value ? value : !this.accountLayer;
      this.infoLayer = false;
      this.menuLayer = false;
      this.userLayer = false;
    },
    // 회원 레이어 토글
    toggleUserLayer(value) {
      this.userLayer = !value ? value : !this.userLayer;
      this.infoLayer = false;
      this.menuLayer = false;
      this.accountLayer = false;
    },
    // 정보 레이어 토글
    toggleInfoLayer(value) {
      this.infoLayer = !value ? value : !this.infoLayer;
      this.userLayer = false;
      this.menuLayer = false;
      this.accountLayer = false;
    },
    // 메뉴 레이어 토글
    toggleMenuLayer(value, type, content) {
      if (type === "sideLayer") {
        this.sideLayer = !value ? value : !this.sideLayer;
        this.sideContent = content;
        this.accountLayer = false;
      } else {
        this.menuLayer = !value ? value : !this.menuLayer;
        this.infoLayer = false;
        this.userLayer = false;
        this.accountLayer = false;
      }
    },
    // 알림 목록
    alarmList(page) {
      const pages = page ? page : this.noCheckAlarm.page + 1;

      const params = {
        page: pages,
        perPage: 20,
        status: ["미확인"],
        sortBy: "-sentAt"
      };
      this.$store
        .dispatch("ModuleAlarm/actAlarmList", params)
        .then(response => {
          const data = response.data;
          if (page === 1) this.noCheckAlarm.list = [];
          this.noCheckAlarm.list.push(...response.data.list);
          this.noCheckAlarm.page = data.header.page;
          this.noCheckAlarm.totalPage = data.header.totalPage;
        });
    },
    // 알림 확인
    alarmCheckUpdate(id) {
      const params = {
        id
      };
      this.$store
        .dispatch("ModuleAlarm/actAlarmCheckUpdate", params)
        .then(() => {
          this.alarmList(1);
        });
    },
    // 알림 전체 확인
    alarmCheckAllUpdate() {
      this.$store.dispatch("ModuleAlarm/actAlarmCheckAllUpdate").then(() => {
        this.alarmList(1);
      });
    },
    // 사이트 추가
    siteAdd() {
      if (this.$store.state.ModuleAuth.loggedData.sites.length > 0) {
        this.setAlert({
          isVisible: true,
          message: `베타 오픈 기간에는 사이트 생성이 1개로 제한됩니다.`
        });
      } else {
        let routeData = this.$router.resolve({
          name: "intro-theme-list",
          query: {
            id: 3,
            type: "site"
          }
        });
        window.open(routeData.href, "_blank");
      }
      this.toggleMenuLayer(false, "sideLayer");
    },
    // 제작 가이드 show/ hide
    userProgreeStep() {
      const params = {
        userId: this.userId
      };
      this.$store
        .dispatch("ModuleUser/actUserProgressStep", params)
        .then(response => {
          const data = response.data;
          let complete = 0;
          // step1 complete
          if (data.userSite && data.userTheme) {
            complete++;
          }
          // step2 complete
          if (data.userTheme && data.userThemeSaveHistory) {
            complete++;
          }
          // step3 complete
          if (data.userSolution) {
            complete++;
          }
          // step4 complete
          if (data.connectSiteToSolution) {
            complete++;
          }
          // step5 complete
          if (data.userThemeExport) {
            complete++;
          }

          complete === 5
            ? this.setUserProgressStepShow(false)
            : this.setUserProgressStepShow(true);
        });
    },

    ...ModuleAuth.mapActions(["actLogged", "actLogout"]),
    ...ModuleAuth.mapMutations(["setLoginStatus"]),
    ...ModuleUser.mapMutations(["setUserProgressStepShow"])
  },
  mounted() {
    this.alarmList(1);
    this.userProgreeStep();
  }
};
</script>

<style lang="scss">
.header__wrapper {
  z-index: 3;
  position: relative;
}
.aside {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  &__inner {
    z-index: 11;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 340px;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    background: #fff;
    overflow-y: auto;
  }
  &--close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    background-image: url($path + "btn_close.svg");
    background-repeat: no-repeat;
    background-position: center center;
    text-indent: -9999px;
  }
  // 미확인 알림
  &__inner--pop {
    width: 372px;
    padding: 0;
    background: #f7f9fc;

    @media screen and (max-width: 1023px) {
      width: 352px;
    }

    @media screen and (max-width: 411px) {
      width: 300px;
    }

    .pop-top-wrap {
      background: #fff;
    }

    .pop-top {
      position: relative;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f1f1f1;
      padding: 12px 0;
      margin: 0 16px;

      .title {
        color: #606060;
        font-weight: 500;

        img {
          margin-left: 4px;
          vertical-align: -1px;
        }
      }

      .confirm-button {
        margin-left: auto;
        border: 1px solid #309574;
        border-radius: 4px;
        width: 84px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
        font-weight: 500;
        font-size: 12px;
        color: #309574;

        img {
          margin-right: 4px;
          vertical-align: -1px;
        }
      }

      .confirm-button-disable {
        border: 1px solid #dcdcdc;
        color: #dcdcdc;
        cursor: default;
      }
    }

    .alarm-wrap {
      background: #f7f9fc;
      padding: 16px;

      .alarm-list-wrap {
        .alarm-list {
          li {
            background: #ffffff;
            border: 1px solid #dadada;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
            border-radius: 8px;
            margin-bottom: 8px;
            padding: 22px 16px;
            box-sizing: border-box;

            &:last-child {
              margin-bottom: 0px;
            }

            .detail-wrap {
              display: flex;
              margin-top: 6px;

              .date {
                color: #8d8d8d;
                font-size: 12px;
              }

              .detail-button {
                margin-left: auto;
                cursor: pointer;

                a {
                  color: #026444;
                  text-decoration-line: underline;
                  font-size: 12px;
                }
              }
            }
          }
        }

        .more-button {
          height: 40px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          border: 1px solid #dcdcdc;
          border-radius: 8px;
          margin-top: 16px;
          color: #b7b7b7;
          font-size: 14px;
        }

        .alarm-list-empty {
          padding-top: 90px;
          text-align: center;

          p {
            font-weight: 500;
            font-size: 14px;
            color: #309574;
            margin-top: 30px;
          }
        }
      }

      .all-button {
        height: 40px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        border: 1px solid #b7b7b7;
        margin-top: 16px;
        color: #8d8d8d;
        font-size: 14px;
      }
    }

    .guide-wrap {
      background: #f7f9fc;
      padding: 16px;

      .step-section {
        margin-left: 0px !important;
        display: block !important;
      }

      .link-button {
        margin-top: 16px !important;
        padding-left: 0 !important;
        display: block !important;

        button {
          width: 100% !important;
        }

        .white-button {
          margin-right: 0px !important;
          margin-bottom: 4px !important;
        }
      }

      @media screen and (max-width: 1024px) {
        .step-title {
          .status {
            width: 22px !important;
            height: 22px !important;
          }

          .text {
            width: calc(100% - 50px) !important;
          }
        }
      }
    }
  }
}
.logNav {
  margin-bottom: auto;
  padding-bottom: 32px;
  border-bottom: 1px solid #f1f1f1;

  &__id {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 32px;
    padding-right: 24px;
    background-image: url($path + "bul_member_regular.svg");
    background-repeat: no-repeat;
    background-position: 0 50%;
  }
  &__id--associate {
    background-image: url($path + "bul_member_associate.svg");
  }
  &__link {
    display: flex;
    align-items: center;
    height: 40px;
    margin-top: 15px;
    background: #f7f9fc;
    border-radius: 8px;

    a {
      flex: 1;
      background-image: url($path + "bg_vline.svg");
      background-repeat: no-repeat;
      background-position: 100% 50%;
      background-size: 1px 12px;
      color: #8d8d8d;
      font-size: 14px;
      text-align: center;
    }
    a:last-child {
      background: none;
    }
  }
}
.sideNav {
  margin-top: -1px;

  section {
    position: relative;
    padding: 26px 0;
    border-top: 1px solid #f1f1f1;
  }
  h3 {
    color: #8d8d8d;
    font-size: 18px;
    font-weight: 400;
  }
  &__toggle {
    position: absolute;
    right: 0;
    top: 26px;
    width: 27px;
    height: 27px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url($path + "ico_math_minus.svg");
    text-indent: -9999px;
  }
  &__toggle--show {
    background-image: url($path + "ico_math_plus.svg");
  }
  &--hide {
    display: none !important;
  }
  li {
    margin-top: 16px;
    margin-left: 4px;
  }
  a {
    padding-left: 22px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    color: #484948;
    font-size: 14px;
    font-weight: 500;
  }
  &__site--none {
    width: 100%;
    height: 64px;
    margin-top: 10px;
    background-color: #f7f9fc;
    border: 1px dashed #d8dae5;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    color: #8d8d8d;

    &::before {
      content: "";
      width: 32px;
      height: 32px;
      margin-right: 8px;
      border-radius: 50%;
      border: 1px solid #b7b7b7;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url($path + "ico_math_plus.svg");
      background-size: 10px;
    }
  }
}
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s ease;
  -webkit-transition: transform 0.5s ease;
  -webkit-transition: -webkit-transform 0.5s ease;
}
.slide-leave-to,
.slide-enter {
  transform: translate(100%, 0);
  -webkit-transform: translate(100%, 0);
}
.opacity-enter-active,
.opacity-leave-active {
  transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  -webkit-transition: -webkit-opacity 0.5s ease;
}
.opacity-enter-from,
.opacity-leave-to {
  opacity: 0;
}
.dimmed {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
.header {
  z-index: 2;
  position: relative;
  flex: 1;
  height: $h;
  background: #ffffff;
  box-sizing: border-box;
  border-bottom: 1px solid #54c7a2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);

  &__container {
    position: relative;
    max-width: $w;
    height: $h;
    margin: 0 auto;
    padding: 0 16px;
  }
  &__logo {
    position: absolute;
    top: 0;
    left: 16px;
    width: 24px;
    height: $h;
    background-image: url($path + "bi_qpick.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }
}
.account {
  &--active {
    position: relative;
    display: block;
    width: 260px;
    height: $h - 2px;
    padding-right: 10px;
    padding-left: 32px;
    box-sizing: border-box;
    text-align: left;
    background-image: url($path + "bul_arr_down.svg");
    background-repeat: no-repeat;
    background-position: 100% calc(50% + 2px);

    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__id {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__name {
    color: #8d8d8d;
    font-size: 14px;
  }
  &__list {
    margin-top: 12px;
    border-top: 1px solid #f1f1f1;

    li {
      margin-top: 12px;
    }
  }
}
.account__layer {
  z-index: 2;
  position: absolute;
  top: 8px;
  left: 48px;
  right: 48px;
  max-width: 309px;
  padding: 20px 16px;
  border: 1px solid #d8dae5;
  box-sizing: border-box;
  @include box;

  button {
    position: relative;
    display: block;
    width: calc(100% - 24px);
    text-align: left;
  }
  .account--live::after {
    content: "";
    position: absolute;
    top: 12px;
    right: -24px;
    width: 24px;
    height: 24px;
    background-color: #54c7a2;
    background-image: url($path + "bul_selected.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 12px;
  }
}
.topNav {
  position: absolute;
  top: 8px;
  right: 16px;
  display: flex;

  > li {
    position: relative;
  }
  > li > button {
    width: 40px;
    height: 40px;
    margin-left: 16px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }
  &__guide {
    background-image: url($path + "icon_flag.svg");
    border: none !important;
    box-shadow: none !important;
    margin-right: -4px;
  }
  &__notice {
    background-image: url($path + "ico_utils_notice.svg");
    border: none !important;
    box-shadow: none !important;
    margin-right: -4px;
  }
  &__notice--active::after {
    content: "";
    position: absolute;
    top: 6px;
    right: 4px;
    width: 10px;
    height: 10px;
    background: #54c7a2;
    border-radius: 5px;
  }
  &__logout {
    background-image: url($path + "ico_utils_logout.svg");
  }
  &__user {
    background-image: url($path + "ico_utils_user.svg");
  }
  &__info {
    background-image: url($path + "ico_utils_info.svg");
  }
  &__menu {
    background-image: url($path + "ico_utils_menu.svg");
  }
}
.topNav__layer {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  width: 184px;
  padding: 32px 24px 24px;
  border: 1px solid #d8dae5;
  box-sizing: border-box;
  @include box;

  &--user {
    width: 144px;
  }
  &--close {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url($path + "ico_modal_close.svg");
    text-indent: -9999px;
  }
  li {
    margin-top: 14px;
  }
  li > button,
  li > a {
    padding-left: 22px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    color: #484948;
    font-size: 14px;
    font-weight: 500;
  }
}
.utils__info {
  &--start {
    background-image: url($path + "bul_info_start.svg");
  }
  &--tip {
    background-image: url($path + "bul_info_tip.svg");
  }
  &--theme {
    background-image: url($path + "bul_info_theme.svg");
  }
  &--component {
    background-image: url($path + "bul_info_component.svg");
  }
  &--notice {
    background-image: url($path + "bul_info_notice.svg");
  }
  &--faq {
    background-image: url($path + "bul_info_faq.svg");
  }
  &--help {
    background-image: url($path + "bul_info_help.svg");
  }
}
.utils__menu {
  &--invite {
    background-image: url($path + "bul_menu_invite.svg");
  }
  &--solution {
    background-image: url($path + "bul_menu_solution.svg");
  }
  &--image {
    background-image: url($path + "bul_menu_image.svg");
  }
  &--export {
    background-image: url($path + "bul_menu_export.svg");
  }
  &--notice {
    background-image: url($path + "bul_menu_notice.svg");
  }
  &--charset {
    background-image: url($path + "bul_menu_charset.svg");
  }
}
.utils__user {
  &--lock {
    background-image: url($path + "bul_user_lock.svg");
  }
  &--logout {
    background-image: url($path + "bul_user_logout.svg");
  }
  &--switch {
    background-image: url($path + "bul_user_switch.svg");
  }
}
.utils__site {
  li {
    margin-left: 0;
  }
  dl {
    display: flex;
    align-items: center;
    color: #484948;
    font-size: 14px;
    font-weight: 500;

    dt {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
    dt img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    dt span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #54c7a2;
      font-weight: 500;
      font-size: 16px;
      color: #fff;
    }
    dd {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .topNav {
    > li > button {
      border: 1px solid #cdcdcd;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
    }
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .topNav {
    > li > button {
      margin-left: 6px;
    }
    &__logout {
      display: none;
    }
    &__user {
      display: none;
    }
    &__info {
      display: none;
    }
    &__menu {
      margin-right: -8px;
    }
  }

  .account--active {
    width: 220px;
  }
}
@media screen and (max-width: 359px) {
  .aside {
    &__inner {
      width: 300px;
    }
  }
}
</style>
